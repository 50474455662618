import React, { useContext } from "react";
import styled from "styled-components";

import media from "@styles/media";
import text from "@styles/text";
import colors from "@styles/colors";

import PrimaryButton from "@components/PrimaryButton";

import { OpenFormContext } from "@components/Layout";

const Features: React.FC = () => {
  const openForm = useContext(OpenFormContext);

  return (
    <Wrapper>
      <HR />
      <TopSection>
        <Left>
          <Title>mIQroAware&trade;</Title>
          <Text>Reduce Costs. Avoid Downtime. Prevent Leaks.</Text>
          <PrimaryButton
            backgroundColor={colors.keppel100}
            hoverColor={colors.keppel60}
            textColor={colors.jetBlack100}
            text="Schedule a Demo"
            onClick={() => openForm(true)}
          />
        </Left>
        <Right>
          <Text>
            The mIQroAware™ monitoring system is a low cost, high impact
            solution with a full suite of integrated technologies that work
            together to provide you with instant, intelligent data on the
            current condition of your pipeline. By blending sensor, Internet of
            Things (IoT), Artificial Intelligence (AI), and Analytics
            technology, this innovative system can detect, analyze, and report
            on risks in real time. You'll have access to a constant, reliable
            stream of insights that equip you to optimize operations and prevent
            disasters.
          </Text>
        </Right>
      </TopSection>
      <Row>
        <Column>
          <Square />
          <SubTitle>Easy installation</SubTitle>
          <Text2>Non-invasive device, no hot tapping required</Text2>
          <Text2>
            Attaches to the outside of the pipeline in less than an hour without
            interrupting the integrity of the pipeline or slowing down
            operations
          </Text2>
          <Text2>Compatible with cellular networks</Text2>
        </Column>
        <Column>
          <Square />
          <SubTitle>Seamless monitoring</SubTitle>
          <Text2>
            No repairs- Service comes with lifetime replacement of hardware.{" "}
          </Text2>
          <Text2>Over the Air Updatable- reduces obsolescence costs </Text2>
          <Text2>
            One stop shop for service and support on all major monitoring
            insights- no need to contact multiple vendors
          </Text2>
          <Text2>
            Reads 7 parameters at a rate of up to 42 times per second{" "}
          </Text2>
          <Text2>Data operations are 100% automated</Text2>
        </Column>
        <Column>
          <Square />
          <SubTitle>Instant alerts and analytics</SubTitle>
          <Text2>
            Advanced analytics computing for consistent, accurate insights
          </Text2>
          <Text2>Suitable for executives and operators alike</Text2>
          <Text2>
            Templated and customizable dashboarding for easy, targeted reporting
          </Text2>
          <Text2>
            Automatic alerts by phone and email of any detected risks
          </Text2>
        </Column>
      </Row>
    </Wrapper>
  );
};

export default Features;

const Wrapper = styled.section`
  padding-top: 5.833vw;
  padding-left: 3.472vw;
  padding-right: 3.472vw;
  padding-bottom: 11.736vw;

  ${media.tablet} {
    padding-top: 10.06vw;
    padding-left: 5.988vw;
    padding-right: 5.988vw;
    padding-bottom: 14.611vw;
  }

  ${media.mobile} {
    padding-top: 21.6vw;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
  }
`;

const HR = styled.hr`
  height: 1px;
  background-color: ${colors.culturedWhite60};
  width: 100%;
  border: none;

  margin-bottom: 3.819vw;

  ${media.tablet} {
    margin-bottom: 6.587vw;
  }

  ${media.mobile} {
    margin-bottom: 14.667vw;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 8.403vw;

  ${media.tablet} {
    margin-bottom: 19.641vw;
  }

  ${media.mobile} {
    margin-bottom: 32vw;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h2`
  color: ${colors.culturedWhite100};

  ${text.desktopXLHeading}
  margin-bottom: 1.389vw;

  ${media.tablet} {
    ${text.tabletMHeading}
    margin-bottom: 2.395vw;
  }

  ${media.mobile} {
    ${text.mobileSHeading}
    margin-bottom: 5.333vw;
  }
`;

const Text = styled.p`
  color: ${colors.culturedWhite60};

  ${text.desktopBodyCopy1}
  margin-bottom: 2.083vw;

  ${media.tablet} {
    ${text.tabletSmallBody}
    margin-bottom: 3.832vw;
  }

  ${media.mobile} {
    ${text.mobileBodyCopy2}
    margin-bottom: 7.467vw;
  }
`;

const Right = styled.div`
  width: 30.208vw;

  ${media.tablet} {
    width: 35.449vw;
  }

  ${media.mobile} {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;

  ${media.tablet} {
    flex-wrap: wrap;
  }

  ${media.mobile} {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  :last-of-type {
    margin-right: 0vw;
  }

  width: 21.042vw;
  margin-right: 10.278vw;

  ${media.tablet} {
    width: 36.287vw;
    margin-right: 8.503vw;
    margin-bottom: 2.156vw;

    :nth-of-type(2n) {
      margin-right: 0vw;
    }
  }

  ${media.mobile} {
    width: 80.8vw;
    margin-right: 0vw;
    margin-bottom: 26.667vw;

    :last-of-type {
      margin-bottom: 0vw;
    }
  }
`;

const SubTitle = styled.h3`
  color: ${colors.culturedWhite60};

  ${text.desktopSHeading}
  margin-bottom: 2.083vw;

  ${media.tablet} {
    ${text.tabletSHeading}
    margin-bottom: 3.593vw;
  }

  ${media.mobile} {
    ${text.mobileSHeading}
    margin-bottom: 8vw;
  }
`;

const Text2 = styled.p`
  color: ${colors.culturedWhite20};

  ${text.desktopBodyCopy2}
  margin-bottom: 2.083vw;

  ${media.tablet} {
    ${text.tabletSmallBody}
    margin-bottom: 3.593vw;
  }

  ${media.mobile} {
    ${text.mobileSmallBody}
    margin-bottom: 8vw;
    width: 66.133vw;
  }
`;

const Square = styled.div`
  background-color: ${colors.darkOrange100};

  width: 1.389vw;
  height: 1.389vw;
  margin-bottom: 1.389vw;

  ${media.tablet} {
    width: 2.395vw;
    height: 2.395vw;
    margin-bottom: 2.395vw;
  }

  ${media.mobile} {
    width: 5.333vw;
    height: 5.333vw;
    margin-bottom: 5.333vw;
  }
`;
